
// vue
import { onMounted, ref } from 'vue';

// icons
import { add, } from 'ionicons/icons';

// components
import { IonCard, IonCardHeader, IonItem, IonIcon, IonLabel, IonCardContent, IonThumbnail, IonBadge,
          IonButtons, IonButton, } from '@ionic/vue';

// composables
import { useI18n } from 'vue-i18n';

export default {
  props: [
    "title",
    "subtitle",
    "icon",
    "paneSize",
    "isButton",
    "badgeColor",
    "value",
    "showHeaderDetailButton",
    "noPadding",
    "actionBtnText",
    "actionBtnIcon",
  ],
  emits: [
    "clickedActionBtn",
  ],
  components: { IonCard, IonCardHeader, IonItem, IonIcon, IonLabel, IonCardContent, IonThumbnail, IonBadge,
                IonButtons, IonButton, },
  setup(_, { slots, emit, }) {
    const { t } = useI18n();

    const isSlotEmpty = ref(true);

    onMounted(() => {
      isSlotEmpty.value = !slots.default;
    });

    return {
      // icons
      add,

      // variables
      isSlotEmpty,

      // methods
      t,
      onClickActionBtn: () => {
        emit('clickedActionBtn');
      },
    }
  }
}
